.Select {
  & > div {
    @apply rounded-xl;

    &:hover {
      border-color: #ccc;
    }
  }

  & input:focus {
    box-shadow: none !important;
  }
}